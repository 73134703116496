import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { ethers, BigNumber } from "ethers";
import { Flex } from "crox-new-uikit";
import useMediaQuery from "use-mediaquery";
import { WalletConnect } from "../../../components/Gadget";
import { fenixContract } from "../../../libraries/fenixContract";
import { helperContract } from "../../../libraries/helperContract";
import { minerContract } from "../../../libraries/minerContract";
import { NFTGAME_CONTRACT_ADDRESS, transformCharacterData } from "../../../utils/constants";
import {
    Chain,
    Address,
    useContractWrite,
    useNetwork,
    usePrepareContractWrite,
    useWaitForTransaction,
    useFeeData,
    useBalance,
    useAccount,
    useContractReads,
  } from "wagmi";
import SelectCharacter from "./SelectCharacter";
//import Init from '../../../components/Gadget/Init'
import myEpicGame from '../../../utils/MyEpicGame.json';
//import InitNFTs from "../../InitNFTs";

import awesomeGame from '../../../utils/StakingToken.json';
import './selectcharacter.scss'
import FENIX_ABI from "../../../models/abi/LobbyFacet.json"; 
import HELPER_ABI from "../../../models/abi/HelperToken.json"; 
import MINER_ABI from "../../../models/abi/MinerFacet.json"; 

function Character(props) {
    const navigate = useNavigate();
    const { refID, collapse } = props
    const [referrer,setReferrer]=useState("")
    //const {userId,pokemons}=props    
    const isMobile = useMediaQuery("(max-width: 600px)")
    const [characterNFT, setCharacterNFT] = useState([]);
    const [bonusWallets, setBonusWallets] = useState([]);
    const [lastDayEntered, setLastDayEntered] = useState([]);
    const [currentDay, setCurrentDay] = useState(0);
    const [enterTypes, setEnterTypes] = useState([1, 4, 5]);
    const [userId, setUserId] = useState(0);

    //const router = useRouter();
    const { chain } = useNetwork();
    const { address } = useAccount();
    const { data: feeData } = useFeeData({ formatUnits: "gwei", watch: false, cacheTime: 60_000 });
    const { data: fenixBalance } = useBalance({
      address: address,
      //token: fenixContract(chain).address,
      staleTime: 20_000,
    });


    /////////////////////////////////////
    const REFERRAL_CACHE="REFERRAL_CACHE"
    const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14

    const currentTime=()=>{
        return Date.now()
    }

    const getReferralCache=()=>{

        let referralCache={
            data:{},
            nextCleanup:new Date().getTime()+TWO_WEEKS
        }  

        try {
            const data=localStorage.getItem(REFERRAL_CACHE)

            if(data){
                referralCache=JSON.parse(data)
            }
        }
        catch(e){
            console.error(e.message)
        }

        return referralCache
    }

    const setReferralToCache=(userId, referrerId)=>{

        const referralCache=getReferralCache()
        const data=referralCache.data


        const item={
            id:userId,
            expiry:new Date().getTime()+TWO_WEEKS,
            referrer:referrerId
        }

        data[userId]=item

        try{
            localStorage.setItem(REFERRAL_CACHE,JSON.stringify(referralCache))
        }
        catch(e){
            cleanUpStorage(data)
        }

    }

    const cleanUpStorage=(data)=>{

      let isDeleted
      let oldest
      let oldestKey
  
  
      //if 14 days have been passed, it removes the cache
      for (const key in data) {
          console.log("key is",key)
          const expiry = data[key].expiry
          if (expiry && expiry <=currentTime()) {
            delete data[key]
            isDeleted = true
          }
      
          //finding the oldest cache in case none of them are expired
          if (!oldest || oldest > expiry) {
            oldest = expiry
            oldestKey=key
          }
      }
  
      //remove the oldest cache if there is no more space in local storage (5 MB)
      if(!isDeleted && oldestKey){
          delete data[oldestKey]
      }
  
      localStorage.setItem(
          REFERRAL_CACHE,
          JSON.stringify({
            data: data,
            nextCleanup:currentTime() + TWO_WEEKS,
          })
      )
  
  }




    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    //const prevUser=usePrevious(userId)

    useEffect(()=>{
        //if(prevUser!==userId){
        //    setReferrer(0)
        //}
        const cache=getReferralCache("REFERRAL_CACHE")

        console.log("CACHE_", cache, userId, refID);
        if(userId && refID) {
          //console.log("CACHE_", cache);
          if(userId in cache.data && Number(cache.data[userId].referrer) === Number(refID) ){
              //setReferrer(cache.data[userId].referrer)
              console.log("CACHE_Present", cache);
          } else {
            console.log("CACHE_NOT Present", cache);
            setReferralToCache(userId,refID)
          }


        } else if(userId in cache.data && Number(refID) > 0 ){
          console.log("CACHE_NO userId_YES_RefID", cache, userId, refID);
          setReferralToCache(userId,refID)
          navigate(`/lobby?referralID=${refID}`)
        } else if(userId in cache.data && Number(cache.data[userId].referrer) > 0 ){
          console.log("CACHE_NO RefID", cache, userId, refID);
          navigate(`/lobby?referralID=${cache.data[userId].referrer}`)
        }

    },[userId,refID])

    const handleChange=(e)=>{
      setReferralToCache(userId,e.target.value)
        setReferrer(e.target.value)
    }    
    /////////////////////////////////////


    //console.log(fenixContract(chain));
    var { data: txn, refetch:refetchAssets } = useContractReads({
        contracts: [
          {
            ...minerContract(chain),
            functionName: "fetchAssets",
            args: [address, 0, 3, 1]
          },             
          {
            ...fenixContract(chain),
            functionName: "currentDay",
          },  
          {
            ...minerContract(chain),
            functionName: "fetchAssets",
            args: ["0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266", 0, 3, 0]
          },                                                                    
        ],
        cacheTime: 300000,
        watch: false,
      });


      var { data: _lastDayEntered, refetch:refetchLastDay } = useContractReads({
        contracts: enterTypes?.map((_type) =>    
        ({
              ...fenixContract(chain),
              functionName: "lastDayEntered",
              args: [_type, address],     
          })
        ),
        cacheTime: 300000,
        watch: false,
      });






      useEffect(() => {
        const fetchNFTMetadata = async () => {
            //console.log('Checking for Character NFT on address:', address);

            console.log("CACHE_refID", txn?.[0]?.[2]?.[0]?.entryId, refID);
            console.log("TXN", txn);
            console.log("FENIX_ABI", FENIX_ABI);
            console.log("HELPER_ABI", HELPER_ABI);
            console.log("MINER_ABI", MINER_ABI);

            let cNFT = [];
            let bonusWs = [];
            //if(txn?.[0]?.[0]?.length == 1) cNFT = [txn?.[0]?.[1]];
            if(txn?.[0]?.[1]?.length >= 1) {
              cNFT = txn?.[0]?.[2];
              setUserId(Number(txn?.[0]?.[2]?.[0]?.entryId));
            }
            if(txn?.[2]?.[1]?.length >= 1) bonusWs = txn?.[2]?.[2];

            if (cNFT.length > 0) {
                //console.log('User has character NFT');
                setCharacterNFT(cNFT)
                if (bonusWs?.length > 0) {
                  //console.log('Bonus wallets found');
                  setBonusWallets(bonusWs)
                  
              }                
                
            } else {
                //console.log('No Character NFT Found');
            }


            if (txn?.[1]?.toNumber() > 0)
              setCurrentDay(txn?.[1]?.toNumber())
            if (_lastDayEntered?.length > 0)
              setLastDayEntered(_lastDayEntered)
        }

        if (address) {
            fetchNFTMetadata();
        }
    }, [address, txn])




    return (
        <Flex className={!collapse ? "container" : "container wider-width"} justifyContent='center' flexDirection='column' mt={isMobile ? '20px' : '50px'} >
            {!address ? (
                <Flex justifyContent='center' flexDirection='column' alignItems='center' mt={isMobile ? 100 : -30}>

                </Flex>

            ) : (
                <Flex justifyContent='center' alignItems='center' mt={isMobile ? 30 : -50}>
                    <SelectCharacter refID={Number(refID)} bonusWallets={bonusWallets} setCharacterNFT={setCharacterNFT} characterNFT={characterNFT} currentDay={currentDay} lastDayEntered={lastDayEntered} refetchAssets={refetchAssets} refetchLastDay={refetchLastDay}/>
                </Flex>
            )}
        </Flex>
    )



}

export default Character




/*
characterNFT.length == 0 ? (
    <Flex justifyContent='center' alignItems='center' mt={isMobile ? 30 : -50}>
        <InitNFTs />
    </Flex>
) : 
*/
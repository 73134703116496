import React, { useState, useEffect } from "react";
import { Flex, Text } from "crox-new-uikit";
import { AnimateKeyframes } from 'react-simple-animate';
import { Icon } from '@iconify/react';
//import { Button } from "../../../components/CommonComponents";
import useMediaQuery from "use-mediaquery";
import { fenixContract } from "../../../libraries/fenixContract";
import { helperContract } from "../../../libraries/helperContract";
import { minerContract } from "../../../libraries/minerContract";
import blankImage from'../../../assets/blank.png';
import { Select, Paper } from '@mantine/core';
import TextField from '@mui/material/TextField';
import toast from "react-hot-toast";
import { Box, TextInput, NumberInput, Input, InputBase, Tabs, rem, Divider } from '@mantine/core';
import { IconPhoto, IconMessageCircle, IconSettings } from '@tabler/icons-react';

import ReactModal from 'react-modal'
import { BigNumber, ethers } from "ethers";
import {
    Chain,
    Address,
    useContractWrite,
    useNetwork,
    usePrepareContractWrite,
    useWaitForTransaction,
    useFeeData,
    useBalance,
    useAccount,
    useContractReads,
  } from "wagmi";
import FENIX_ABI from "../../../models/abi/LobbyFacet.json";  
import HELPER_ABI from "../../../models/abi/HelperToken.json"; 
import MINER_ABI from "../../../models/abi/MinerFacet.json"; 
import ReactiveButton from 'reactive-button';
let isConfirm = false
import 'react-circular-progressbar/dist/styles.css';
import "./Card.scss";
import styled from "styled-components";

function Card(props) {
    const { refID, character, row, indexX, levels, referCard, gameContract, currentDay, lastDayEntered, lobbyWallet, walletList, bonusWallets, dividend, dividends, minerCounts, refetchAssets, refetchLastDay, refetchGlobals, refetchDividends, refetchDailyData, refetchWaasLobby, setTableUpdate} = props


    

    const { collapse } = props
    const [err, setErr] = useState(false)
    const [success, setSuccess] = useState(false)
    const [action, setAction] = useState([])
    const [allocation, setAllocation] = useState(0)
    //const [levels, seLevels] = useState(levels)
    const isMobile = useMediaQuery("(max-width: 600px)")

    const { chain } = useNetwork();
    const { address } = useAccount();
    const { data: feeData } = useFeeData({ formatUnits: "gwei", watch: false, cacheTime: 60_000 });
    const { data: fenixBalance } = useBalance({
      address: address,
      //token: fenixContract(chain).address,
      staleTime: 20_000,
    });

    const [added, setAdded] = useState(false)
    const [_disable, setDisable] = useState(false)
    const [_disableField, setDisableField] = useState(false)
    const [toggle, setToggle] = useState(false)  
    const [_inDay, setInDay] = useState(false)
    const [_inPatch, setInPatch] = useState(false)
    const [formInput, updateFormInput] = useState({ lobbyAmount: BigNumber.from("0"), plsAmount: 1, referrerID: 0, boxId: 0, tabId: 1000002, numOfMiners: 1, address: address})
    const [minerVals, updateMinerVals] = useState({ bonusAdd: 0, twoXMul: 1, qtyMul: 1, withSub: 0, rawAmt: BigNumber.from("0"), endedMiners: 0, totalMiners: 0, fullWith: 0})
    const [divAmt, setDivAmt] = useState(BigNumber.from("0"))
    const [minerPay, setMinerPay] = useState(BigNumber.from("0"))
    const [endedValue, setEndedValue] = useState(0)

    const [minerTotal, setMinerTotal] = useState(0)
    const [bonus, setBonus] = useState(0)
    const [isNetworkSelectModalOpen, setIsNetworkSelectModalOpen] = useState(false);
    const [isLinkModalOpen, setIsLinkModalOpen] = useState(false);
    const [lobbyType, setLobbyType] = useState(1);
    const [refMax, setRefMax] = useState(1);
    const [txnIndex, setTxnIndex] = useState(0);
    const [ex, SetExes] = useState(0);
    const [_lockedDay, _setLockedDay] = useState(0)
    const [collected, setCollected] = useState(false);
    const [xValue, setValue] = useState([0, 0]);
    const [isSelectModalOpen, setIsSelectModalOpen] = useState(false);

    const iconStyle = { width: rem(12), height: rem(12) };

    const arrayRange = (start, stop, step) =>
      Array.from(
      { length: (stop - start) / step + 1 },
      (value, index) => start + index * step
      );

  /*** CONTRACT WRITE SETUP ***/
  
  const { config: lobbyEntryConfig, isLoading: lobbyEntryIsoading } = usePrepareContractWrite({
    address: fenixContract(chain).address,
    abi: FENIX_ABI.abi,
    chainId: chain?.id,
    functionName: "xfEnter",
    args: [(formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, 
      !isNaN(Number(formInput?.lobbyAmount)) && parseFloat(formInput?.lobbyAmount) >= 1 ? 
        formInput?.lobbyAmount : 0],

    overrides: {
      value: ethers.utils.parseEther(parseFloat(formInput.plsAmount) >= 1 ? String(Number(formInput.plsAmount)) : String(0)),
      gasLimit: 14900000,
    },
    //enabled: false,
  });

  const { data: lobbyEntryWriteData, isLoading: lobbyEntryWriteLoading, write: lobbyEntryWrite } = useContractWrite({
    ...lobbyEntryConfig,
    //onSuccess(_data) {
    //  reset;
    //  setProcessing(true);
    //  setDisabled(true);
    //},
  });

  const { isLoading: entryTxIsLoaging  } = useWaitForTransaction({
    hash: lobbyEntryWriteData?.hash,
    onSuccess(data) {
      toast.success("Your miner has been claimed. Happy earning!");
      refetchAssets?.();
      refetchLastDay?.();
      refetchGlobals?.();
      refetchDividends?.();
      refetchDailyData?.();
      refetchWaasLobby?.();
      //router.push("/lobby/active");
    },
    onError(err) {
      toast.error("Miner claim was unsuccessful. Please try again later.");
    },    
  });



  const { config: lobbyExitConfig, isLoading: lobbyExitIsoading } = usePrepareContractWrite({
    address: fenixContract(chain).address,
    abi: FENIX_ABI.abi,
    chainId: chain?.id,
    functionName: "xfLobbyExit",
    //args: [character?.entryId, character?.lockedDay, formInput.numOfMiners],
    args: [character?.entryId, formInput.numOfMiners],
    overrides: {
      //value: ethers.utils.parseEther(formInput.plsAmount),
      gasLimit: 30000000,
    },
    //enabled: false,
  });

  const { data: lobbyExitWriteData, isLoading: lobbyExitWriteLoading, write: lobbyExitWrite } = useContractWrite({
    ...lobbyExitConfig,
    //onSuccess(_data) {
    //  reset;
    //  setProcessing(true);
    //  setDisabled(true);
    //},
  });

  const { isLoading: exitTxIsLoaging } = useWaitForTransaction({
    hash: lobbyExitWriteData?.hash,
    onSuccess(data) {
      toast.success("Your auction to miner has been claimed. Happy earning!");
      refetchAssets?.();
      refetchLastDay?.();
      refetchGlobals?.();
      refetchDailyData?.();
      refetchWaasLobby?.();      
      setTableUpdate?.(true);
      //router.push("/lobby/active");
    },
    onError(err) {
      toast.error("Auction claim was unsuccessful. Please try again later.");
    },    
  });

  /*** CONTRACT WRITE SETUP ***/
  
  const { config: minerTransactConfig, isLoading: minerTransactIsoading } = usePrepareContractWrite({
    address: minerContract(chain).address,
    abi: MINER_ABI.abi,
    chainId: chain?.id,
    functionName: "minerTransact",

    //minerTransact(uint256 day, uint256 _masterId, uint256 quantity, uint256 transactionTag, uint256 rawAmount, address userAddr)
    args: [
      //character?._lockedDay, 
      //value?.[0]?.[0],  // _MinerID
      !isNaN(character?._lockedDay) && character?._lockedDay > 0 ? Number(character?._lockedDay) : 0,
      Number(character.entryId),
      (!isNaN(xValue?.[0]?.[0]) && xValue?.[0]?.[0]) > 0 ? Number(xValue?.[0]?.[0]) - minerVals.endedMiners : 1,

      //Math.ceil(Number((divAmt*(Number(minerVals.qtyMul)))-(Number(character?.withdrawals) / (10**18))) / divAmt)

      formInput.tabId == 1000002 ? formInput.tabId : Number(minerVals.qtyMul + (Number(refID) * 10000000)), // Transaction type or Index
      formInput.tabId == 1000002 ?  formInput?.lobbyAmount : minerPay,
      //!isNaN(Number(formInput?.lobbyAmount)) && parseFloat(formInput?.lobbyAmount) >= 1 ? ethers.utils.parseEther(String(formInput?.lobbyAmount)) : String(0),
      //ethers.utils.parseEther(String(1)),
      formInput?.address], //User Address


    overrides: {
      //value: ethers.utils.parseEther(formInput.plsAmount),
      gasLimit: 30000000,
    },

  });



  const { data: minerTransactWriteData, isLoading: minerTransactWriteLoading, write: minerTransactWrite } = useContractWrite({
    ...minerTransactConfig,
    //onSuccess(_data) {
    //  reset;
    //  setProcessing(true);
    //  setDisabled(true);
    //},
    onError(error) {
      //toast.error("Miner claim was unsuccessful. Please try again later.");
      console.log("WriteError:", error);
    },      
  });


  const { isLoading: stakeTxIsLoaging } = useWaitForTransaction({
    hash: minerTransactWriteData?.hash,
    onSuccess(data) {
      toast.success("Your miner has been claimed. Happy earning!");
      refetchAssets();
      refetchLastDay();
      refetchGlobals();
      refetchDividends();
      refetchDailyData();
      refetchWaasLobby();    
      setTableUpdate(true);  
      setDisable(true);
      //router.push("/lobby/active");
    },
    onError(error) {
      //toast.error("Miner claim was unsuccessful. Please try again later.");
      console.log("Error:", error);
    },    
  });

  let formatter = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 2, maximumFractionDigits: 2 });
  let formatter_4 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 4, maximumFractionDigits: 4 });
  let formatter_6 = Intl.NumberFormat('en', { notation: 'compact', minimumFractionDigits: 6, maximumFractionDigits: 6 });


  const typehHandler = (event) => {
    if(character.entryType == 0) {
      if(referCard == true) {
        setLobbyType(0);
        formInput.referrerID = Number(character?.entryId);
      }
    }
    if(character.entryType == 1 || (character.entryType == 0 && referCard == false)) setLobbyType(1);
    if(character.entryType == 4) {
      setLobbyType(3);
      if(formInput.referrerID === 0)
        formInput.referrerID = Number(lobbyWallet);
      formInput.lobbyAmount = 0;
    }
    if(character.entryType == 5) setLobbyType(2);

    //console.log("character.entryType", formInput.referrerID, character.entryType, lobbyType, referCard)
    //console.log("formInput", formInput, lobbyType, lobbyWallet);

  };
    

    const handleTabChange = (newValue) => {
      //console.log(newValue);
      if(newValue == 0) 
        updateFormInput({ ...formInput, lobbyAmount: 0 });
      let boxId = 2;
      if(newValue == 1000000) boxId = 1;
      updateFormInput({ ...formInput, tabId: newValue, boxId: boxId })    
    }

    const handleInputChange = (newValue) => {
      //console.log(newValue);
      updateFormInput({ ...formInput, lobbyAmount: newValue, boxId: 2 });

      if(newValue < 10000)
        setErr("GAS WARNING 10K | 5K PSL MINIMUM !!!")
      else 
        setErr(false)
      
    }


  function truncateNumber(number, decimalPlaces) {
      const factor = Math.pow(10, decimalPlaces);
      return Math.floor(number * factor) / factor;
  }

    function copyToClipBoard() {
        
        var x = document.getElementById("snackbar");
        x.className = "show";
        setTimeout(function () { x.className = x.className.replace("show", ""); }, 3000);

        if(isLinkModalOpen){
          var y = document.getElementById("copie");
          y.className = "show";
          setTimeout(function () { y.className = y.className.replace("show", ""); }, 3000);        
  
        }
    }




    function closeModal() {
      updateFormInput({ ...formInput, boxId: 0 });
      setIsNetworkSelectModalOpen(false);
      setDivAmt(BigNumber.from("0"));
      setErr(false);
    }

    function closeLinkModal() {
      //setIsNetworkSelectModalOpen(false);
      //setIsSelectModalOpen(false);
      setIsLinkModalOpen(false);
  }

    const customStyles = {
        overlay: {
            position: 'fixed',
            zIndex: 1000,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 1)'
        },        
        content: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            right: 'auto',
            bottom: 'auto',
            
            border: 'none',
            overflow: 'hidden',
            backgroundColor: 'rgba(0, 0, 0, 1)',
            position: 'absolute',
            WebkitOverflowScrolling: 'touch',
            outline: 'none',

        },
      
    };

    ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0)';



  

  //console.log(chain);
  //console.log(fenixContract(chain).address);
  
  //console.log("...value", Number(character.entryId), xValue);
  //
  /*
  console.log("...minerTransact",       
    !isNaN(character?._lockedDay) && character?._lockedDay > 0 ? character?._lockedDay : 0,
    Number(character.entryId),
    //!isNaN(xValue?.[0]?.[0]) && xValue?.[0]?.[0] > 0 ? xValue?.[0]?.[0] - minerVals.endedMiners: 1,
    
    Number(divAmt) > 0 && !isNaN(minerVals.qtyMul) ? Math.ceil(Number((divAmt.mul(minerVals.qtyMul).sub(character?.withdrawals)).div(divAmt))) : 0,
    formInput.tabId == 1000002 ? formInput.tabId : Number(minerVals.qtyMul), // Transaction type or Index
    //formInput.tabId == 1000002 ? formInput?.lobbyAmount : minerPay,
    //formInput.tabId == 1000002 ? ethers.utils.parseEther(String(Number(formInput?.lobbyAmount))) : (ethers.utils.parseEther(String(minerPay))).sub(ethers.utils.parseEther("0.000000001")),
    formInput.tabId == 1000002 ? formInput?.lobbyAmount : minerPay,
    //ethers.utils.parseEther(String(Number(formInput?.lobbyAmount))),
    //String(Number(formInput?.lobbyAmount)),
    formInput?.address //User Address
  );
    
  */


  //
  //

  //console.log("...Enter", (formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, 
  //    !isNaN(Number(formInput?.lobbyAmount)) && parseFloat(formInput?.lobbyAmount) >= 1 ? 
  //      formInput.lobbyAmount
  //    : 0);
  //console.log("...walletList", walletList, Number(character?.oneFiveX), walletList?.indexOf(Number(character?.oneFiveX)), Number(bonusWallets?.[Number(character?.oneFiveX)]?.rawAmount));
  
  
  //console.log("+++Bonus", walletList?.indexOf(Number(character?.oneFiveX)));
  
  // Wallet #, Wallet veiw index, 
  // Number(bonusWallets?.[walletList?.[Number(character?.oneFiveX) - 1]]?.rawAmount), Number(character?.oneFiveX));
  //console.log("dividend", dividend);
  //console.log("dividend", dividend);
  //console.log("minerCounts", minerCounts);
  console.log("formInput", formInput, lobbyType, lobbyWallet);
  //console.log("MINER VALUES", minerVals, divAmt, minerPay, endedValue);
  //console.log("minerTotal", Number(minerTotal), Number(character?.stakeShares));
  //console.log(" Allocation ", character)
  //console.log("lobbyType", (formInput.referrerID).toString(), (lobbyType).toString(), formInput.address, 0);
  //console.log("PRECISION...", Number(divAmt), Number(minerVals.qtyMul), Number(character?.withdrawals), Number(divAmt.mul(minerVals.qtyMul).sub(character?.withdrawals)));  
  //console.log("PRECISION...", (Number(formInput.lobbyAmount)/(10**18)).toFixed(18), dividend, minerVals.totalMiners, (Number(character?.withdrawals) / (10**18)), Number(dividend.mul(minerVals.totalMiners).sub(character?.withdrawals)) / (10**18));
  //console.log("PRECISION...", BigNumber.from(Number(String(formInput.lobbyAmount))));



  


  

useEffect(() => {


  //console.log("BOX: ", formInput.boxId);
  if(character?.status === 7) SetExes(2);
  else if(character?.status === 5 && (Number(currentDay) - Number(character?.unlockedDay) >= 5)) SetExes(1.1);
  else SetExes(Number(dividend / (10 ** 18)) / (parseInt(character?.rawAmount?._hex, 16) / (10 ** 18)));
           
  if((character?.status === 7 || character?.lockedDay === 1) && (formInput.boxId != 4 && formInput.boxId != 0)){
    //let minerTotal = ((Number(character?.rawAmount) * 2 / (10 ** 18) )  - (Number(character?.withdrawals) / (10**18)) );
    updateMinerVals({ ...minerVals, rawAmt: character?.rawAmount, twoXMul: 2, qtyMul: Number(xValue[0]?.[0])})
    if(character?.lockedDay === 1) {
      //minerTotal = Number(character?.stakeShares)  / (10**18);
      updateMinerVals({ ...minerVals, rawAmt: character?.stakeShares})
    } else {
      if(Number(formInput.lobbyAmount) <= 0 && Number(dividend.mul(minerVals.totalMiners).sub(character?.withdrawals)) / (10**18)  >= 1) updateFormInput({ ...formInput, lobbyAmount: (character?.rawAmount).mul(2).mul(minerVals.totalMiners).sub(character?.withdrawals)   });
    }
    //updateFormInput({ ...formInput, lobbyAmount: (Number(minerTotal) ) ?? 1});
    //setMinerTotal((Number(minerTotal) - 25));
  }

  else if (formInput.boxId != 4 && formInput.boxId != 0) {  
    let bns = 0;
    formInput.tabId === 1000000 && (character?.status === 5 && (Number(currentDay) - Number(character?.unlockedDay)) >= 5) ? bns = 0 : bns = (((5-walletList?.indexOf(Number(character?._oneFiveX)))/5) * Number(bonusWallets?.[Number(character?._oneFiveX)]?.stakedSuns)  / (10**18) * (Number(character?.rawAmount)  / Number(character?.minerPulse))  );
    bns = isNaN(bns) ?  BigNumber.from("0") :  bns;
    updateMinerVals({ ...minerVals, rawAmt: dividend, twoXMul: 1, qtyMul: Number(xValue[0]?.[0]), bonusAdd: bns});
    if(Number(formInput.lobbyAmount) <= 0 && Number(dividend.mul(minerVals.totalMiners).sub(character?.withdrawals)) / (10**18) >= 1) updateFormInput({ ...formInput, lobbyAmount: isNaN(Number(dividend)) ? BigNumber.from("0") : dividend.mul(minerVals.totalMiners).sub(character?.withdrawals) });
    //setMinerTotal(~~Number(((Number(dividend) / (10 ** 18)) + (isNaN(bns) ? 0 : bns) - (Number(character?.withdrawals) / (10**18)) ) - 25));
    //setMinerTotal(Number(((Number(dividend) / (10 ** 18)) + (isNaN(bns) ? 0 : bns) - (Number(character?.withdrawals) / (10**18))) * Number(xValue[0]?.[0]) - 25));
  } 

  if(character?.status >= 4 && character?.status <= 7 ){
    setDivAmt(((minerVals.rawAmt).mul(minerVals.twoXMul)));
    setEndedValue((minerVals.rawAmt).mul(minerVals.endedMiners));
  }

  if(character?.entryType == 0 && formInput.boxId == 4 && !isNaN(Number(character?.price)) && !isNaN(Number(character?.refBonus)) && parseFloat(Number(character?.refBonus)) >= 1  && parseFloat(Number(character?.price)) >= 1){
    if(((Number(character?.refBonus)  / (10**18)) / (Number(character?.price) / 10 ** 18)) > 8000000000 ){
      updateFormInput({ ...formInput, lobbyAmount: ethers.utils.parseEther((character?.price).mul(8000000000)) });
      setRefMax((8000000000 * Number(character?.price)  / (10**18)));
    
    } else if(character?.entryType == 0 ) {
      updateFormInput({ ...formInput, lobbyAmount: character?.refBonus });
      setRefMax((Number(character?.refBonus)  / (10**18)) );
    }
  }



  

}, [character, dividend, formInput.boxId, formInput.tabId, xValue, toggle])



useEffect(() => {

  if(character?.status >= 4 && character?.status <= 7 && !isNaN(minerVals.qtyMul)) {
    //setMinerPay(Number((divAmt * minerVals.qtyMul)-(Number(character?.withdrawals) / (10**18))) > 0 ? Number((divAmt * minerVals.qtyMul)-(Number(character?.withdrawals) / (10**18))) : 0);

    setMinerPay(
    Number(divAmt.mul(minerVals.qtyMul).sub(character?.withdrawals)) >= Number(divAmt) ? 
      divAmt 
    : Number(divAmt.mul(minerVals.qtyMul).sub(character?.withdrawals)) <= 0 ? 
    BigNumber.from(String("0")) 
    : Number(divAmt.sub(character?.withdrawals)) < 0 ? 
      divAmt.mul(minerVals.qtyMul).sub(character?.withdrawals) 
    : divAmt.sub(character?.withdrawals) )
    //divAmt.sub(character?.withdrawals) > divAmt ? divAmt
    //: divAmt.sub(character?.withdrawals) )

    updateMinerVals({ ...minerVals, fullWith: Number(character?.withdrawals) / Number(Number(divAmt) > 0 ? divAmt : dividend), totalMiners: (((Number(character?.minersMod) - (Number(character?.minersMod) % 100)) / 1000 )),  endedMiners: ((((Number(character?.minersMod) - (Number(character?.minersMod) % 100)) / 1000 )) - (Number(character?.minersMod) % 100))});
  } else setMinerPay(BigNumber.from("0"));

}, [divAmt, formInput.boxId, formInput.tabId, minerVals.rawAmt, minerVals.twoXMul, minerVals.qtyMul])



useEffect(() => {

  _setLockedDay(((Number(character?.lockedDay) - (Number(character?.lockedDay) % 100000)) / 100000 ));

  let val = "";
  let calc = 0;
  if (character) {
    if(levels == 'Duelists' && (character.entryType === 0 || character.entryType === 1)) {
      val = [1, "Add PLS", "Lobby Entry "];
      setDisable((Number(currentDay) !== Number(character.lockedDay)));
    };
    if(levels == 'Duelists' && character.entryType === 5) {
      val = [1, "Add PLS", "Yield Lobby "];
      setDisable((Number(currentDay) !== Number(character.lockedDay)));
    };
    if(levels == 'BonusYielder' && character.entryType === 4) {
      val = [1, "Add PLS", "Bonus Yield Card"];
      setDisable((Number(currentDay) !== Number(character.lockedDay)));
    };
    if(levels == 'Initiators') {
      val = [1, "Mine PLS", "Auction Entry "];
      calc = (Number(character.rawAmount) / 10 ** 18) * (Number(character.price) / 10 ** 18);
      setAllocation(calc);
    };
    if(levels == 'Referrers') {
      val = [1, "Enter Lobby"];
    };
    if(levels == 'Controllers') {
      val = [1, "Collect", "MINERS "];
      calc = (Number(character.rawAmount) / 10 ** 18) * (Number(character.price) / 10 ** 18);
      setAllocation(calc);      
    };
    if(levels == 'Sentials') {
      val = [1, "2X Exit", "Miners "];
    };

    if(character.entryType == 0 && referCard == true) {
      val = [1, "Redeem"];
    }    
    setAction(val);
  }
  
}, [character])


useEffect(() => {
  if (levels == "Duelists" && currentDay != lastDayEntered?.[0]?.day) {
      setDisableField(true);
  }
}, [currentDay, lastDayEntered?.[0]])  


useEffect(() => {
  if (Number(dividend) > 0) {
    Object.assign(character, { dividend: parseInt(dividend?._hex, 16) /  10 ** 18} )
    Object.assign(character, { xfactor: (parseInt(dividend?._hex, 16) / (parseInt(character?.stakedSuns?._hex, 16))) } )
    setInPatch(true)
  }
}, [dividend])  


useEffect(() => {

    if(formInput.tabId === 1000002) {setTxnIndex(1000002)}
    else {setTxnIndex(0)}
  
}, [formInput.tabId])  





    return (
        <Flex
            className={`card_parent_${String(character.entryType)}_${levels}`}
            justifyContent="center"
            onFocus={() => typehHandler()}
            onMouseEnter={() => typehHandler()}
            onTouchStart={() => typehHandler()}
        >
            {levels === "Initiatorx" ? (
                <>
                    <div className="initiatorx_wrapper">
                        <div className="bxwrapper">
                            <div className="value">{character.lockedDay}</div>
                            <div
                                className="heading"
                                style={{ color: "#d9511c !important" }}
                            >
                                Day Number
                            </div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {Number(character.groupId)}
                            </div>
                            <div className="heading">Index ID</div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {Number(character.entryId)}
                            </div>
                            <div className="heading">Entry ID</div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {Number(character.referenceId)}
                            </div>
                            <div className="heading">Referrer ID</div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {formatter_4.format(
                                    parseInt(character?.rawAmount?._hex, 16) /
                                        10 ** 18
                                )}
                            </div>
                            <div className="heading">PLS In</div>
                        </div>
                        <div className="bxwrapper">
                            <div className="value">
                                {allocation > 0
                                    ? formatter.format(allocation)
                                    : "Pending"}
                            </div>
                            <div className="heading">P2X</div>
                        </div>
                        <div className="bxwrapper">
                            {allocation > 0 && (
                                <Button
                                    primary
                                    className={`orange_btn btn_${levels}`}
                                    onClick={() => setIsSelectModalOpen(true)}
                                >
                                    <svg
                                        width="22"
                                        height="23"
                                        viewBox="0 0 22 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11 1.5L21 8M11 1.5L1 8M11 1.5V8M21 8V15M21 8L11 15M21 15L11 21.5M21 15L11 8M11 21.5L1 15M11 21.5V15M1 15V8M1 15L11 8M1 8L11 15"
                                            stroke="#F1EDE9"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>

                                    {action[1]}
                                </Button>
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <Flex
                        className={`refcardsWrapper card_parent_${String(
                            character.entryType
                        )}_${levels}`}
                        justifyContent="center"
                    >
                        <Flex
                            className={`card_child card_child_${levels}`}
                            justifyContent="center"
                        >
                            <Flex
                                flexDirection="column"
                                className={`gameCard card_${String(
                                    character.entryType
                                )}_${levels}`}
                                width="100%"
                                alignItems="center"
                                mr="10px"
                                ml="10px"
                            >

                                <Flex
                                    className="referCardHeading"
                                    alignItems="center"
                                    mb="10px"
                                >
                                    {character.entryType === 0 &&
                                    referCard === true ? (
                                        <>
                                            <Flex
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                                style={{ width: "100%" }}
                                            >
                                                <Text
                                                    fontSize="18px"
                                                    m="0 10px"
                                                    bold
                                                >
                                                    Click to Copy
                                                </Text>
                                            </Flex>
                                            <div
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                      "http://testnet2.tru2x.io/lobby?referralID=" +
                                                      Number(character.entryId)
                                                    );

                                                    setIsLinkModalOpen(
                                                      true
                                                  )                                                    
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <Icon
                                                    icon="fluent:copy-24-filled"
                                                    color="rgba(199, 185, 178, 0.8)"
                                                    width="30"
                                                    height="30"
                                                />
                                            </div>
                                        </>
                                    ) : character?.levels === "BonusYielder" ? (
                                        <Flex
                                            flexDirection="column"
                                            className=""
                                            alignItems="center"
                                            justifyContent="center"
                                            style={{ width: "100%" }}
                                        >
                                            <Text fontSize="18px" ml="3px" bold>
                                                {action[2]}
                                            </Text>

                                        </Flex>
                                    ) : character?.levels === "Duelists" ||
                                      character?.levels === "Initiators" ? (
                                        <Flex
                                            flexDirection="column"
                                            className=""
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Text fontSize="18px" ml="3px" bold>
                                                {action[2] +
                                                    Number(character.groupId)}
                                            </Text>
                                            <Text fontSize="18px" ml="3px" bold>
                                                {"ID: " +
                                                    Number(character.entryId) +
                                                    " | Ref: " +
                                                    character.referenceId}
                                            </Text>
                                    {/*+" "+(Number(character._dayP2XPool) / 10 ** 18)+" "+(Number(character.rawAmount) / 10 ** 18)+" "+(Number(character.auctionPulse) / 10 ** 18)*/}
                                        </Flex>
                                    ) : (
                                        <Flex
                                            flexDirection="row"
                                            className=""
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                          <Text fontSize="22px" ml='3px' color="#e9e4e1ee" >{"ID: "+character?.entryId}</Text>
                                          <Text fontSize="22px" ml='3px'color="#e9e4e1ee">{" | Miners: "+Number(character?.minersMod) % 100}</Text>
                                          
                                          

                                            {/*<Text fontSize="18px" ml='3px' bold>{levels+" "+character.status+" "+character.entryType+" ID: "+Number(character.entryId)}</Text>*/}

                                        </Flex>
                                    )}
                                </Flex>
                                <Flex
                                    flexDirection="column"
                                    className="refNameWrapper"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    {character.entryType === 0 &&
                                    referCard === true ? (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="refName"
                                            bold
                                        >
                                            {Number(character.entryId)}
                                            <h5>Referral ID</h5>
                                        </Text>
                                    ) : character?.levels === "BonusYielder" ? (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="refName"
                                            bold
                                        >
                                            {Number(lobbyWallet)}
                                            <h5>Wallet ID</h5>

                                        </Text>
                                    ) : character?.levels === "Initiators" ? (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="initiatorsName"
                                            bold
                                        >
                                            {Number(lobbyWallet)}
                                            <h5>Day Number</h5>
                                        </Text>
                                    )  : character?.levels === "Initiators" ? (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="initiatorsName"
                                            bold
                                        >
                                            {Number(lobbyWallet)}
                                            <h5>Day Number</h5>
                                        </Text>
                                    ) : (
                                        <Text
                                            fontSize="100px"
                                            mb="20px"
                                            color="black"
                                            className="refName"
                                            bold
                                        >
                                            {character.lockedDay}
                                            <h5>Day Number</h5>
                                        </Text>
                                    )}

                                </Flex>

                                <div className="refBottomWrapper">
                                    {character.entryType === 0 &&
                                    referCard === true ? (
                                        <>
                                            <div className="entryPriceWrapper">
                                                {formatter.format(
                                                    parseInt(
                                                        character?.refBonus
                                                            ?._hex,
                                                        16
                                                    ) /
                                            10 ** 18
                                                )}
                                                <h6>Referral Bonus</h6>
                                            </div>
                                        </>
                                    ) : levels === "Duelists" ? (
                                    <>
                                        <div className="entryPriceWrapper">
                                            {formatter_4.format(
                                                parseInt(
                                                    character?.rawAmount
                                                        ?._hex,
                                                    16
                                                ) /
                                                    10 ** 18
                                            )}
                                            <h6>Entry Price</h6>
                                        </div>
                                    </>
                                ) : levels === "BonusYielder" ? (
                                        <>
                                            <div className="entryPriceWrapper">
                                                {formatter_4.format(
                                                    parseInt(
                                                        character?.rawAmount
                                                            ?._hex,
                                                        16
                                                    ) /
                                                        10 ** 18
                                                )}
                                                <h6>Balance</h6>
                                            </div>
                                        </>
                                    ) : levels === "Initiators" ? (
                                        <>
                                            <Flex
                                                flexDirection="column"
                                                className=""
                                                alignItems="left"
                                                justifyContent="center"
                                            >


                                            <div className="entryPriceWrapper">
                                            <h6>Entry Price</h6>
                                                {formatter_4.format(
                                                    parseInt(
                                                        character?.rawAmount
                                                            ?._hex,
                                                        16
                                                    ) /
                                                        10 ** 18
                                                )}
                                               
                                            </div>




                                                <Flex>
                                                    <Text
                                                        fontSize="14px"
                                                        ml="5px"
                                                        mr="30px"
                                                        bold
                                                    >
                                                        P2X{" "}
                                                    </Text>
                                                    <Icon icon="fluent-emoji:dollar-banknote" />
                                                    {allocation > 0 ? (
                                                        <Text
                                                            fontSize="14px"
                                                            ml="5px"
                                                            mr="30px"
                                                            bold
                                                        >
                                                            {formatter.format(
                                                                allocation
                                                            )}
                                                        </Text>
                                                    ) : (
                                                        <Text
                                                            fontSize="14px"
                                                            ml="5px"
                                                            mr="30px"
                                                            bold
                                                        >
                                                            Pending
                                                        </Text>
                                                    )}
                                                </Flex>
                                            </Flex>
                                        </>
                                    ) : (levels === "Controllers" || levels === "Controllerx") ? (
                                      <>
                                    <Flex flexDirection='column'>
                                    <Flex justifyContent="end">
                                        { Number(character?.dayDividends) === 0 ? (
                                        <>
                                        <Text fontSize="18px" ml='5px' mr='3x' color="orange">{"0x"}</Text>
                                        </>
                                      ) : character?.entryId < 21 ? (
                                        <>
                                        <Text fontSize="18px" ml='5px' mr='3x' color="orange">{"2X"}</Text>
                                        </>
                                      ) : character?.status  === 7 ? (
                                        <>
                                        <Text fontSize="18px" ml='5px' mr='3x' color="orange">{"2X"}</Text>
                                        {/*<Text fontSize="18px" ml='5px' mr='30px' color="green" bold>{ (formatter.format(parseInt(character?.stakeShares?._hex, 16) / (10 ** 18)) ?? 0) +" → (2X)"}</Text>*/}
                                        </>
                                      ) : (
                                        <Text fontSize="18px" ml='5px' mr='3x' color="orange">{""+formatter.format((Number(dividend / (10 ** 18)) ?? 0) / ((parseInt(character?.rawAmount?._hex, 16) / (10 ** 18))  ?? 0))+"x"}</Text>
                                      )
                                      }
                                    </Flex>                                      

                                            <div className="entryPriceWrapper">


                                            { Number(character?.dayDividends) === 0 ? (
                                        <>
                                        { "0.00"}
                                        </>
                                      ) : character?.entryId < 21 ? (
                                        <>
                                       {formatter.format(Number(character?.auctionPulse) * 0.8 / 5 / (10 ** 18))}
                                        </>
                                      ) : character?.status  === 7 ? (
                                        <>
                                        {formatter.format(parseInt(character?.rawAmount?._hex, 16) * 2 / (10 ** 18))}
                                        </>
                                      ) : (
                                        <>
                                        {formatter.format(dividend / (10 ** 18))}
                                        </>
                                      )
                                      }
                                        
                                        </div>                                                                              
                                        
                                        <Flex>
                                      <Text fontSize="18px" ml='1px' mr='3x' color="orange" bold>{"Dividends"}</Text>
                                      </Flex>                                            


                                      </Flex>



                                      <Flex flexDirection='column' className=""  alignItems='left' justifyContent='center'>

                                      <Flex>
                                      <Text fontSize="22px" mt='20px' mr='3px' color="green" >{ formatter.format(parseInt(character?.rawAmount?._hex, 16) / (10 ** 18))+" → "+formatter.format(parseInt(character?.stakedSuns?._hex, 16) / (10 ** 18)) + " P2X"}</Text>
                                      </Flex> 
                                       
                                      {/*<Flex>
                                      <Text fontSize="18px" mt='1px' mr='1x' color="#b7b5b5ee"  bold>{"Entry"}</Text>
                                      </Flex>*/}
            
                                      {/* character?.status  !== 7 ? (
                                        <Flex mt='10px'>
                                        <Text fontSize="16px" ml='5px' mr='30px' color="#625c5c" bold>SHARES:</Text>
                                        
                                        <Text fontSize="16px" ml='5px' mr='30px' color="#625c5c" bold>{formatter.format(parseInt(character?.stakeShares?._hex, 16) / (10 ** 18))}</Text>
                                        </Flex>
                                      ) : (
                                        <></>
                                      )
                                      */}
                                      </Flex>
                                      </>
                                    ) : (
                                        <></>
                                    )}
















                                    {/*<Icon icon="noto:crossed-swords" />
                                        <Text fontSize="14px" ml='5px' bold>{character.attackDamage}</Text>*/}

                                    <Flex
                                        className="refBottomBtnWrapper"
                                        justifyContent="center"
                                        m={isMobile ? "20px 10px" : "20px"}
                                    >
                                        {character.entryType === 0 &&
                                        referCard === true ? (
                                            <Button
                                                primary
                                                className={`orange_btn btn_${levels}`}
                                                onClick={() => {updateFormInput({ ...formInput, boxId: 4 }), setIsNetworkSelectModalOpen(true)}}
                                            >
                                                {action[1]}
                                            </Button>
                                        ) : levels === "Initiators" ? ( // ...or Bonus Yielder
                                            allocation > 0 && (
                                                <Button
                                                    primary
                                                    className={`orange_btn btn_${levels}`}
                                                    onClick={() =>
                                                        setIsNetworkSelectModalOpen(
                                                            true
                                                        )
                                                    }
                                                >
                                                    {action[1]}
                                                </Button>
                                            )
                                        ) : character.entryType === 4 ? ( // ...or Bonus Yielder
                                            <Button
                                                primary
                                                className={`orange_btn btn_${levels}`}
                                                onClick={() =>
                                                    setIsNetworkSelectModalOpen(true)
                                                }
                                            >
                                                {action[1]}
                                            </Button>
                                        ) : (
                                          <Button color="#56ccf2" 
                                          primary
                                          disabled={ _disable ? true : false } className={_disable ? "" : `orange_btn btn_${levels}  btn_${levels}`} onClick={() => {updateFormInput({ ...formInput, boxId: 2 }), setIsNetworkSelectModalOpen(true)}}>
                                          {_disable ? "Collected" : (
                                              <>
                                              <Flex flexDirection='column' color="#56ccf2">
                                              <Text>{action[1]}</Text>
                                              <Divider  my="xs" label="or" labelPosition="center" />
                                              <Text>Compound</Text>
                                              </Flex>
                                              </>
                                              )}
                                            </Button>
                                        )}

                                        <div id="snackbar"><Text fontSize="24px" ml="5px" mr="1px" color="cyan" >Copied</Text></div>
                                        
                                    </Flex>
                                </div>
                            </Flex>
                        </Flex>
                    </Flex>
                </>
            )}



            
            <ReactModal
                isOpen={isNetworkSelectModalOpen}
                onRequestClose={() => closeModal()}
                style={customStyles}
            >
                <Flex flexDirection="column" className="accountModal">
                {levels === "Initiators" ? (
                              <>
                            <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                            <Text fontSize="24px" ml='5px' mr='30px' color="orange" bold>EXIT LOBBY - MINE PLS</Text>
                            </Flex>
                            <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                            <Text fontSize="14px" ml='5px' mr='30px' color="red" bold>Auction ID: </Text>
                            <Text fontSize="14px" ml='5px' mr='30px' bold>{(parseInt(character?.entryId?._hex, 16))}</Text>
                            </Flex>
                            <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                            <Text fontSize="14px" ml='5px' mr='30px' color="red" bold>Allocation: </Text>
                            <Text fontSize="14px" ml='5px' mr='30px' bold>{formatter_4.format(allocation)+" P2X"}</Text>
                            </Flex>          
                            <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                              <TextField
                                      label="Enter # of Miners to Start"
                                      defaultValue = {formInput.numOfMiners}
                                      variant="filled"
                                      type="number"
                                      sx={{ input: { color: 'white' } }}
                                      InputLabelProps={{
                                          style: { color: '#ff4655' },
                                      }}
                                      className="inputField"
                                      onChange={e => updateFormInput({ ...formInput, numOfMiners: e.target.value })}
                                  />
                            </Flex>   
                            </>
                        ) : (levels === "Controllers" || levels === "Controllerx") ? (
                          <>


                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>

    <Tabs color="orange" defaultValue={"1000002"} onTabChange={e => handleTabChange(Number(e)) }>

      <Tabs.List >
        <Tabs.Tab value="1000002" leftSection={<IconPhoto style={iconStyle} />}>
        <Text fontSize="24px" ml='5px' mr='30px' color="#fff" bold>COMPOUND DIVS</Text>
        </Tabs.Tab>
        <Tabs.Tab value="1000000" leftSection={<IconMessageCircle style={iconStyle} />}>
        <Text fontSize="24px" ml='5px' mr='30px' color="#fff" bold>EXIT MINER</Text>
        </Tabs.Tab>
      </Tabs.List>

      <Flex ml='30px' mt='30px' mb='5x' justifyContent='space-between'>

<TextInput
    label="Exit to a different TruDefi address? Copy & Paste it below:"
    defaultValue = {formInput.address}
    value = {formInput.address}
    error={err}
    //disabled={character?.lockedDay === 1 || minerTotal < 25}
    //max={minerTotal}                                  
    sx={{ input: { background: '#1c1a19', color: 'pink', width: '500px', fontSize: '18px' }, label: { color: 'orange', fontSize: '16px' } }}
    InputLabelProps={{
      style: { color: '#ff4655' },
    }}                                  
    //className="inputField"
    onChange={e => { 
      var value = e.target.value;
      if (ethers.utils.isAddress(value) === false) value = '0x0';                                      
      updateFormInput({ ...formInput, address: value });
  }}
    
/>


</Flex>  



 















      <Tabs.Panel value="1000002" m='20px' sx={{ border: '0px solid grey', padding: '30px', borderRadius: 15 }}
      
      onFocus={() => {updateFormInput({ ...formInput, tabId: 1000002 }), setToggle(!toggle)  }} 
      onMouseEnter={() => {updateFormInput({ ...formInput, tabId: 1000002 }), setToggle(!toggle)  }} 
      onTouchStart={() =>  {updateFormInput({ ...formInput, tabId: 1000002 }), setToggle(!toggle)  }}    
      
      >
      
                            <Flex alignItems='right' width="100^">
                            <Text fontSize="30px" ml='5px' mr='1px' color="#FF6E36" bold>{"COMPOUNDS"}</Text>
                            </Flex> 
                            <Flex alignItems='right' mb='40px'   width="100^">
                            <Text fontSize="18px" ml='5px' mr='1px' color="#FFF" >{"Card ID# " + character?.entryId + " - (DAY"}</Text>
                            <Text fontSize="18px" ml='5px' mr='5px' color="#FFF" bold>{character?.lockedDay + ")"}</Text>
                            </Flex> 

                          <Flex  mb='40px' justifyContent='space-between'>
                          <TextInput
                                  label="Enter (PLS) Total to Lobby"
                                  defaultValue = {(Number(formInput.lobbyAmount)/(10**18)).toFixed(18)}
                                  variant="filled"
                                  //allowDecimal={false}
                                  //value={(Number(formInput.lobbyAmount)/(10**18)).toFixed(18)}
                                  error={err}
                                  //min={5000}
                                  min={10}
                                  disabled={character?.lockedDay === 1 }
                                  //max={minerTotal}                                  
                                  sx={{ input: { background: '#1a1a19', color: 'white', fontSize: '18px',  width: '310px' }, label: { color: '#FF6E36', fontSize: '16px' } }}
                                  //className="inputField"
                                  //onChange={handleInputChange}

                                  
                                  onChange={e => { 
                                    var value = e.target.value;
                                    
                                    updateFormInput({ ...formInput, lobbyAmount: ethers.utils.parseEther(value), boxId: 2 });

                                    if(value < 100)
                                      setErr("GAS WARNING 10K | 5K PSL MINIMUM !!!")
                                    else 
                                      setErr(false)

                                }}                                  
                                  
                                  //onPaste={(e) => {
                                  //  e.preventDefault();
                                  //  return false;
                                  //}} 
                                  
                              />
                             <Text fontSize="14px" ml='50px' mr='5px' bold>25 PLS Reserved</Text>
                             
                          </Flex>
                          <>
                                {/*<ReactiveButton disabled={character?.lockedDay === 1 || minerTotal < 25 || entryTxIsLoaging || stakeTxIsLoaging || collected} idleText={
                                  <Flex alignItems='center'>
                                      <Icon icon="clarity:logout-line" color="#f4516c" width="15" height="15" />
                                      <Text fontSize="14px" ml="3px" bold>Compound Your DIVS!</Text>
                                  </Flex>
                              } size="large" onClick={() => {minerTransactWrite(),closeModal()}} 
                              
                              
                              onFocus={() => typehHandler()} 
                              onMouseEnter={() =>  typehHandler()} 
                              onTouchStart={() =>  typehHandler()}                               
                              
                              color="green" outline rounded />     */}

     

                                <Flex alignItems='center' mt='20px' justifyContent='space-between'>
                                <Text fontSize="18px" ml='5px' mr='30px' color="#FF6E36">Total to Lobby: </Text>


                              {/*<ReactiveButton disabled={character?.lockedDay === 1 || Number(formInput?.lobbyAmount)/(10**18) < 5000 || entryTxIsLoaging || stakeTxIsLoaging || collected} idleText={
                                  <Flex alignItems='center'>
                                      <Icon icon="clarity:logout-line" color="#f4516c" width="15" height="15" />
                                      <Text fontSize="18px" ml='5px' mr='30px' color="#03fc13" bold>{(formatter_6.format(Number(formInput.lobbyAmount)/(10**18))  ?? 0)+" PLS"}</Text>
                                  </Flex>
                              } size="large" onClick={() => {updateFormInput({ ...formInput, boxId: 2 }),minerTransactWrite(),closeModal()}} 
                              color="green" outline rounded /> */}


                              <ReactiveButton disabled={character?.lockedDay === 1 || Number(formInput?.lobbyAmount)/(10**18) < 10 || entryTxIsLoaging || stakeTxIsLoaging || collected} idleText={
                                  <Flex alignItems='center'>
                                      <Icon icon="clarity:logout-line" color="#f4516c" width="15" height="15" />
                                      <Text fontSize="18px" ml='5px' mr='30px' color="#fff" bold>{(formatter_6.format(Number(formInput.lobbyAmount)/(10**18))  ?? 0)+" PLS"}</Text>
                                  </Flex>
                              } size="large" onClick={() => {updateFormInput({ ...formInput, boxId: 2 }),minerTransactWrite(),closeModal()}} 
                              color="green" outline rounded /> 


                                
                                </Flex>         

                          <Flex alignItems='center' mt='20px' mb='20px' justifyContent='space-between'>
                            <Text fontSize="18px" ml='5px' mr='30px' color="#FF6E36">Previous  Withdrawals: </Text>
                            <Text fontSize="18px" ml='5px' mr='3px' color="white" bold>{formatter_6.format((Number(character?.withdrawals) / (10**18))) ?? 0}</Text>
                          </Flex>                                

                          <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                              <Text fontSize="18px" ml='5px' mr='30px' color="#FF6E36">Dividends Total:</Text>
                              <Flex alignItems='center'>
                              <Text fontSize="18px" ml='1px' mr='1px' color="white" bold>{(formatter_6.format(Number(divAmt * minerVals.totalMiners) / (10**18)) ?? 0) }</Text>
                              <Text fontSize="18px" ml='5px' mr='5px' color="orange" bold>{" ( " + minerVals.totalMiners + " Miners )"}</Text>
                              </Flex>
                            </Flex>


                          {/*<Flex alignItems='center' mt='30px' justifyContent='space-between'>
                                <Text fontSize="16px" ml='5px' mr='10px' color="red" bold>Note:</Text>
                                <Text fontSize="16px" ml='2px' mr='2px' color="pink" bold>The entered amount will redeemed from each of your</Text>
                                <Text fontSize="18px" ml='2px' mr='2px' color="yellow" bold>{Number(character?.minersMod) % 100}</Text>
                                <Text fontSize="16px" ml='5px' mr='30px' color="pink" bold> miners. </Text>
                            </Flex>    */}
                              </>                        
                          
      </Tabs.Panel>

      <Tabs.Panel value="1000000">



      <Box m='20px' sx={{ border: '0px solid grey', padding: '30px', borderRadius: 15 }}

onFocus={() => {updateFormInput({ ...formInput, tabId: formInput.boxId == 1 ? 1000000 : 1000002 }), setToggle(!toggle)  }} 
onMouseEnter={() => {updateFormInput({ ...formInput, tabId: formInput.boxId == 1 ? 1000000 : 1000002 }), setToggle(!toggle)  }} 
onTouchStart={() =>  {updateFormInput({ ...formInput, tabId: formInput.boxId == 1 ? 1000000 : 1000002 }), setToggle(!toggle)  }}  
>

                        <Flex alignItems='right' width="100^">
                        <Text fontSize="30px" ml='5px' mr='1px' color="#FF6E36" bold>{"E  X  I  T  S"}</Text>
                        </Flex> 
                        <Flex alignItems='right' mb='40px'   width="100^">
                        <Text fontSize="18px" ml='5px' mr='1px' color="#fff" bold>{"Card ID# " + character?.entryId + " - (DAY"}</Text>
                        <Text fontSize="18px" ml='5px' mr='5px' color="#fff" bold>{character?.lockedDay + ")"}</Text>
                        </Flex> 

                        <Flex alignItems='center' mb='20px'mt='20px' justifyContent='space-between'>
                        <Text fontSize="18px" ml='5px' mr='30px' color="#FF6E36">Select Miner #: </Text>



                        <Select
                        
                        //data={arrayRange(1, Number(character?.minersMod) % 100, 1)?.map((item, index) => ({  disabled: index >= minerVals.totalMiners - minerVals.fullWith ? true : false, value: [Number(item), index], label: `${Number(index) + 1}`+" → "+ formatter_4.format(Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18))) > 0 ? Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18))) : 0) + " PLS" }))}
                        //data={arrayRange(1, Number(character?.minersMod) % 100, 1)?.map((item, index) => ({  value: [Number(item), index], label: `${Number(index) + 1}`+" → "+ formatter_4.format(Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18))) > 0 ? Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18))) : 0) + " PLS" }))}
                        data={arrayRange(1, minerVals.totalMiners, 1)?.map((item, index) => ({  
                          disabled: index < minerVals.endedMiners  ? true : false, 
                          value: [Number(item), index], 
                          label: "→ "
                          //+ formatter_4.format(Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18))) >= 1 ? Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18))) : 0) 
                          + formatter_6.format(Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) )) < 1 ? 
                            0 : 
                            Number((Number(divAmt)*(Number(index) + 1))-(Number(character?.withdrawals) )) > Number(divAmt) ?
                            Number(divAmt) / (10**18) :
                            Number((Number(divAmt)*(Number(index) + 1))-(Number(character?.withdrawals) )) / (10**18)
                          ) 
                          + " PLS"




                          //>=  Number(divAmt) ?
                          //Number(divAmt) :
                          //Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18))))) 



                                  //+`${index < minerVals.endedMiners ? 0 : 
                                  //  (Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18))) / divAmt) < 0 ? 0 :
                                  //    `${Math.ceil((Number((divAmt*(Number(index) + 1))-(Number(character?.withdrawals) / (10**18)))) / divAmt)}`}`
                                  //+")" 
                                }))
                        }
                        value={xValue[0]?.[0]}
                          placeholder={xValue[0]?.[0] ?? "Select a Miner"}
                          defaultValue={"Select a Miner"}
                          //isOptionDisabled={(option) => option.isdisabled}
                          onChange={(_value, options) => {setDisable(false), setValue([_value, 1+_value[1]+(minerCounts?.slice(0, indexX))?.reduce(function(a, b) { return a + b; }, 0)])}}
                          sx={{
                            width: '45%',
                            color: '#FF6E36',
                            input: {
                               "&::placeholder": {    // <----- Add this.
                                  opacity: 1,
                                  color: 'white'
                               }                              
                            },   
                          }}  

                          styles={(theme) => ({
                            item: {
                              fontSize: '18px',
                              color: 'cyan',
                              //backgroundColor: 'orange',
                              // applies styles to hovered item (with mouse or keyboard)
                              '&[data-hovered]': {color: 'orange'},
                            },
                          })}                      
                          //onChange={() => setValue(value)}

                          //onClick={() => setIsNetworkSelectModalOpen(true)}
                        />


                        {/*<Text fontSize="16px" ml='5px' mr='30px' bold>{xValue[0]?.[0]}</Text>*/}
                        </Flex>



 


                        <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                        <Text fontSize="16px" ml='5px' mr='30px' color="#FF6E36">Dividends per Miner: </Text>
                        <Text fontSize="18px" ml='5px' mr='3px' color="#fff" bold>{ `${(Number(divAmt)/(10**18)).toFixed(18)}` + " PLS"}</Text>
                        </Flex>   



                        <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                        <Text fontSize="16px" ml='5px' mr='30px' color="#FF6E36">Selected Value: </Text>
                        <Text fontSize="18px" ml='5px' mr='3px' color="#fff" bold>{ `${(Number(minerPay)/(10**18)).toFixed(18)}` + " PLS"}</Text>
                        </Flex> 



                        <>
                            {  ex >= 1.5 && ex < 2 && formInput.tabId === 1000000? (
                              <>
                                <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                                    <Text fontSize="18px" ml='5px' mr='30px' color="#FF6E36">+ Bonus: </Text>
                                    {/*<Text fontSize="14px" ml='5px' mr='30px' color="black" bold>{'BW '+formatter_4.format(Number(bonusWallets?.[Number(character?._oneFiveX)]?.stakedSuns)  / (10**18))}</Text>
                                    <Text fontSize="14px" ml='5px' mr='30px' color="black" bold>{'IN '+formatter_4.format(Number(character?.rawAmount) / (10**18))}</Text>
                                    <Text fontSize="14px" ml='5px' mr='30px' color="black" bold>{'PL '+formatter_4.format(Number(character?.minerPulse / (10**18)))}</Text>*/}
                                    <Flex alignItems='center' mb='20px'>
                                        <Text fontSize="18px" color="#fff" mr='1px'>{"("}</Text>
                                        <Text fontSize="18px" ml='3px' color="orange">{"bonusPool "+(character?._oneFiveX ?? 0)+" | "+ (Number(character?._oneFiveX) === 0 ? 0 : ((5-walletList?.indexOf(Number(character?._oneFiveX)))/5)*100 )+"%"}</Text>
                                        <Text fontSize="18px" color="#fff" mr='1px'>{")"}</Text>
                                        <Text fontSize="18px" ml='10px' color="#fff" mr='1px'>{formatter_4.format(((((5-walletList?.indexOf(Number(character?._oneFiveX)))/5) * Number(bonusWallets?.[Number(character?._oneFiveX)]?.stakedSuns)  / (10**18)) * (Number(character?.rawAmount)  / Number(character?.minerPulse)))  ) + " PLS"}</Text>                           
                                    </Flex>
                                </Flex>
                              </>
                            ) : (
                              <></>
                            )}
                            </>

                            <Flex alignItems='center' mb='20px' justifyContent='space-between'>



                            {  formInput.tabId === 1000000? (
                              <>
                                <Text fontSize="18px" ml='5px' mr='30px' color="#FF6E36">Exit to Wallet Total: </Text>
                                </>
                            ) : (
                              <>
                              <Flex>
                              <Text fontSize="18px" ml='5px' mr='5x' color="#FF6E36">Total to Lobby</Text>
                              {/*<Text fontSize="18px" ml='5px' mr='5px' color="white" bold>[+exit]</Text>*/}
                              <Text fontSize="18px" color="red" bold>:</Text>
                              </Flex>
                              </>
                            )}






                            <ReactiveButton 
                            disabled={(Math.ceil(Number((divAmt*(Number(minerVals.qtyMul)))-(Number(character?.withdrawals) / (10**18))) / divAmt) < 1 && minerVals.twoXMul < 2) || entryTxIsLoaging || stakeTxIsLoaging || collected} 
                            idleText={
                                  <Flex alignItems='center'>
                                      <Icon icon="clarity:logout-line" color="#f4516c" width="15" height="15" />
                                      <Text fontSize="18px" ml='5px' mr='30px' color="#fff">{(formatter_6.format((Number(minerPay) / (10**18)) + (     (ex >= 1.5 && ex < 2) ? ((((5-walletList?.indexOf(Number(character?._oneFiveX)))/5) * Number(bonusWallets?.[Number(character?._oneFiveX)]?.stakedSuns)  / (10**18)) * (Number(character?.rawAmount)  / Number(character?.minerPulse))) : 0)     ))+" PLS"}</Text>
                                  </Flex>
                              } size="large" onClick={() => {updateFormInput({ ...formInput, boxId: 1 }),minerTransactWrite(),closeModal()}} 
                              color="green" outline rounded />   


                                </Flex>  



                          



                            <Flex alignItems='center' mb='5px' justifyContent='space-between'>
                              <Text fontSize="18px" ml='5px' mr='30px' color="#FF6E36" >Previous Withdrawals: </Text>
                              <Flex alignItems='center'>
                              <Text fontSize="18px" ml='5px' mr='0px' color="white" >{(formatter_6.format((Number(character?.withdrawals) / (10**18))) ?? 0) }</Text>
                              <Text fontSize="18px" ml='5px' mr='3px' color="orange" >{" ( " + minerVals.endedMiners + " of " +(((Number(character?.minersMod) - (Number(character?.minersMod) % 100)) / 1000 )) + " )"}</Text>
                              </Flex>
                            </Flex>


                            {/*<Flex alignItems='center' mt='30px' justifyContent='space-between'>
                                <Text fontSize="16px" ml='5px' mr='10px' color="red" bold>Note:</Text>
                                <Text fontSize="16px" ml='2px' mr='2px' color="pink" bold>The entered amount will redeemed from each of your</Text>
                                <Text fontSize="18px" ml='2px' mr='2px' color="yellow" bold>{Number(character?.minersMod) % 100}</Text>
                                <Text fontSize="16px" ml='5px' mr='30px' color="pink" bold> miners. </Text>
                            </Flex> */}


                            <Flex alignItems='center' mt='3px' justifyContent='space-between'>


                            {  formInput.tabId !== 1000000? (
                              <>
                                <Text fontSize="16px" ml='5px' mr='10px' color="red" bold>Note:</Text>
                                <Text fontSize="18px" ml='2px' mr='2px' color="yellow" bold>Quantity</Text>
                                <Text fontSize="16px" ml='2px' mr='2px' color="pink" bold>compounds disables once any </Text>
                                <Text fontSize="18px" ml='3px' mr='2px' color="yellow" bold>Amount</Text>
                                <Text fontSize="16px" ml='5px' mr='30px' color="pink" bold> compounds are made. </Text>
                         
                              </>
                            ) : (
                              <></>
                            )}



                            </Flex>  




</Box>


{/* 

      { ex >= 2 ? (
                              <>
                                <ReactiveButton disabled={ true || entryTxIsLoaging || stakeTxIsLoaging || collected} idleText={
                                  <Flex alignItems='center'>
                                      <Text fontSize="14px" ml="3px" bold>Collect Your 2X!</Text>
                                  </Flex>
                              } size="large" onClick={() => {minerTransactWrite(),closeModal()}} color="green" outline />                              
                              </>

                            ) : ex >= 1.5 && ex < 2 ? (
                              <>
                                <ReactiveButton disabled={true || entryTxIsLoaging || stakeTxIsLoaging || collected} idleText={
                                  <Flex alignItems='center'>
                                      <Text fontSize="14px" ml="3px" bold>Exit + 1.5X Bonus</Text>
                                  </Flex>
                              } size="large" onClick={() => {minerTransactWrite(),closeModal()}} color="green" outline />                              
                              </>

                            ) : ex > 0 && ex < 1.5 ? (
                              <>
                                <ReactiveButton disabled={true || entryTxIsLoaging || stakeTxIsLoaging || collected} idleText={
                                  <Flex alignItems='center'>
                                      <Text fontSize="14px" ml="3px" bold>Exit & Collect P2X</Text>
                                  </Flex>
                              } size="large" onClick={() => {minerTransactWrite(),closeModal()}} color="green" outline />                              
                              </>

                            ) : (
                              <>
                                  <ReactiveButton disabled={true || entryTxIsLoaging || stakeTxIsLoaging} idleText={
                                    <Flex alignItems='center'>
                                        <Text fontSize="14px" ml="3px" bold>No PLS to Collect</Text>
                                    </Flex>
                                } size="large"  color="green" outline />                            
                              </>
                            )}

*/}


      </Tabs.Panel>

    </Tabs>
                            </Box>



                        </>
                    ) : character?.entryType === 0 && referCard === true ? (  
                              <>
                            <Flex alignItems='center' mb='5px' justifyContent='space-between'>
                            <Text fontSize="26px" ml='30px' mr='30px' mb='30px' color="white" bold>REDEEM REFERRALS TO MINER</Text>
                            </Flex>
                            <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                            <Text fontSize="16px" ml='30px' mr='30px' color="#FF6E36" bold>Referals: </Text>
                            <Text fontSize="16px" ml='5px' mr='30px' bold>{formatter_4.format(Number(character?.refBonus ?? 0) / (10 ** 18))+" P2X"}</Text>
                            </Flex>
                            <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                            <Text fontSize="16px" ml='30px' mr='30px' color="#FF6E36" bold>Price: </Text>
                            <Text fontSize="16px" ml='5px' mr='30px' bold>{"@ "+formatter_6.format(Number(character?.price ?? 0) / (10 ** 18))+' P2X/PLS'}</Text>
                            </Flex>          
                            <Flex alignItems='center' mb='0px' justifyContent='space-between'>
                            <Text fontSize="16px" ml='30px' mr='30px' color="#FF6E36" bold>Receive: </Text>
                            <Text fontSize="16px" ml='5px' mr='30px' bold>{formatter_4.format(Number(character?.refBonus ?? 0) / Number(character?.price ?? 0) )+" PLS"}</Text>
                            </Flex>   





                            <Paper mb='20px' bg="#1d201c" shadow="xs" p="xl">
                            <Flex mb='5px' flexDirection="column" justifyContent='space-between'>
                            <label
                                    htmlFor="Enter-pls-Amount"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "12px",
                                    }}
                                >
                                    Enter P2X Amount [Defaults @ Max] 
                                </label>                                
                            <TextField
                                  disabled = {parseFloat(character?.price) / (10 ** 18) <= 0 || parseFloat(character?.refBonus) / (10 ** 18) < 1}
                                  
                                  defaultValue = {(Number(formInput.lobbyAmount)/(10**18)).toFixed(18)}
                                  value = {(Number(formInput.lobbyAmount)/(10**18)).toFixed(18)}
                                  //value={parseFloat(formInput?.lobbyAmount) >= 1 ? Number(formInput?.lobbyAmount) : 0}
                                  variant="filled"
                                  type="number"
                                  sx={{ input: { color: 'white'} }}
                                  InputLabelProps={{
                                    
                                    shrink: true,
                                      style: { color: '#FF6E36' },
                                  }}
                                  className="inputField"
                                  onChange={e => { 
                                    var value = e.target.value;
                                    if (Number(value) > refMax) value = refMax;
                                    if (Number(value) < 1) value = 1;                                      
                                    updateFormInput({ ...formInput, lobbyAmount: ethers.utils.parseEther(String(value)) });
                                }} 
                                //onPaste={(e) => {
                                //  e.preventDefault();
                                //  return false;
                                //}}  
                              />
                          </Flex>                            
                            <Flex flexDirection="column" mb='5x' justifyContent='space-between'>
                            <label
                                    htmlFor="Enter-pls-Amount"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "12px",
                                    }}
                                >
                                    Enter Wallet Address
                                </label>                                 
                                <TextField
                                            disabled = {parseFloat(character?.price) / (10 ** 18) <= 0 || parseFloat(character?.refBonus) / (10 ** 18) < 1}
                                            defaultValue = {formInput?.address}
                                            value = {formInput?.address}
                                            variant="filled"
                                            type="string"
                                            sx={{ input: { color: 'white', width: '400px' } }}
                                            InputLabelProps={{
                                                style: { color: '#ff4655' },
                                            }}
                                            className="inputField"
                                            onChange={e => { 
                                              var value = e.target.value;
                                              if (ethers.utils.isAddress(value) === false) value = '0x0';                                      
                                              updateFormInput({ ...formInput, address: value });
                                          }}                                            
                                        />       
                          </Flex>  
                          <Flex alignItems='left' mb='0px' justifyContent='space-between' flexDirection='column' >
                          <Text fontSize="14px" mt='10px' ml='5px' mr='30px' color="orange" bold>Using different address? Copy & Paste it above</Text>
                          <Text fontSize="14px" mt='20px' mb='0px' ml='5px' mr='30px' color="red" bold>Warning: Valid TruDefi user addresses only!</Text>
                          </Flex>     
                          </Paper>                         
                            </>
                        ) : levels === "Duelists" || levels === "BonusYielder" ? (
                          <>
                          <Flex alignItems='center' mb='20px' justifyContent='space-between'>
                              {character.entryType === 1 || character.entryType === 0 ? (
                                <Text fontSize="24px" ml='5px' mr='30px' color="white" bold>ADD PLS TO LOBBY</Text>
                              ) : character.entryType === 4 ? (
                                <Text fontSize="24px" ml='5px' mr='30px' color="white" bold>ADD TO YIELD BONUS </Text>
                              ) : (
                                <Text fontSize="24px" ml='5px' mr='30px' color="white" bold>ADD YIELD TO LOBBY</Text>
                              )}                            
                          
                          </Flex>                          
                          <Flex flexDirection="column" ml='10px' mb='20px' justifyContent='space-between'>
                          <label
                                    htmlFor="Enter-pls-Amount"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "12px",
                                    }}
                                >
                                    Enter (PLS )Amount
                                </label>                              
                          <TextField
                                  defaultValue = {formInput?.plsAmount}
                                  value={formInput?.plsAmount}
                                  variant="filled"
                                  type="number"
                                  sx={{ input: { color: 'white' } }}
                                  InputLabelProps={{
                                      style: { color: '#ff4655' },
                                  }}
                                  className="inputField"
                                  onChange={e => { 
                                    var value = e.target.value;
                                    if (value > 8000000000) value = 8000000000;
                                    if (value < 1) value = 1;                                      
                                    updateFormInput({ ...formInput, plsAmount: value });
                                }} 
                                //onPaste={(e) => {
                                //  e.preventDefault();
                                //  return false;
                                //}}  
                              />
                          </Flex>
                          <Flex flexDirection="column" ml='10px' mb='20px' justifyContent='space-between'>
                          <label
                                    htmlFor="Enter-pls-Amount"
                                    style={{
                                        color: "#FF6E36",
                                        marginBottom: "12px",
                                    }}
                                >
                                    Enter Referrer ID
                                </label>                              
                                <TextField
                                            disabled = {_inDay}
                                            defaultValue = {formInput?.referrerID}
                                            value = {formInput?.referrerID}
                                            variant="filled"
                                            type="number"
                                            sx={{ input: { color: 'white' } }}
                                            InputLabelProps={{
                                                style: { color: '#ff4655' },
                                            }}
                                            className="inputField"
                                            onChange={e => { 
                                              var value = e.target.value;
                                              if (value > 10) value = 10;
                                              if (value < 1) value = 1;                                      
                                              updateFormInput({ ...formInput, referrerID: value });
                                          }} 
                                        />       
                          </Flex>                          
                          </>
                        ) : (<></>)}  

                    <Flex alignItems="center" justifyContent="flex-end">
                        {character.entryType === 0 && referCard === true ? (
                            <ReactiveButton
                                disabled={entryTxIsLoaging || exitTxIsLoaging}
                                idleText={
                                    <Flex alignItems="center">
                                        <Icon
                                            icon="clarity:logout-line"
                                            color="#f4516c"
                                            width="15"
                                            height="15"
                                        />
                                        <Text fontSize="14px" ml="3px" bold>
                                            Redeem to Miner
                                        </Text>
                                    </Flex>
                                }
                                size="large"
                                onClick={() => {
                                    lobbyEntryWrite(), closeModal();
                                }}
                                color="green"
                                outline
                                rounded
                            />
                        ) : character.entryType === 4 ? (
                            <ReactiveButton
                                disabled={entryTxIsLoaging || exitTxIsLoaging}
                                idleText={
                                    <Flex alignItems="center">
                                        <Icon
                                            icon="clarity:logout-line"
                                            color="#f4516c"
                                            width="15"
                                            height="15"
                                        />
                                        <Text fontSize="14px" ml="3px" bold>
                                            Add to Bonus
                                        </Text>
                                    </Flex>
                                }
                                size="large"
                                onClick={() => {
                                    lobbyEntryWrite(), closeModal();
                                }}
                                color="green"
                                outline
                                rounded
                            />
                        ) : levels === "Initiators" ? (
                            <>
                                <Flex flexDirection="column" alignItems="left">
                                    <ReactiveButton
                                        mb="10px"
                                        disabled={
                                            entryTxIsLoaging ||
                                            exitTxIsLoaging ||
                                            character?._day === currentDay
                                        }
                                        idleText={
                                            <Flex
                                                alignItems="center"
                                                justifyContent="flex-end"
                                            >
                                                {/* <Icon
                                                    icon="clarity:logout-line"
                                                    color={
                                                        character?._day ===
                                                        currentDay
                                                            ? "#f4516c"
                                                            : "gray"
                                                    }
                                                    width="15"
                                                    height="15"
                                                /> */}
                                                <Text
                                                    fontSize="14px"
                                                    color={
                                                        character?._day ===
                                                        currentDay
                                                            ? "#504f52"
                                                            : ""
                                                    }
                                                    ml="3px"
                                                    bold
                                                >
                                                    Start Miners
                                                </Text>
                                            </Flex>
                                        }
                                        size="large"
                                        onClick={() => {
                                            lobbyExitWrite(), closeModal();
                                        }}
                                    />
                                    {character?._day === currentDay ? (
                                        <Text
                                            fontSize="16px"
                                            color="yellow"
                                            mt="10px"
                                            ml="3px"
                                            bold
                                        >
                                            You can deploy your miners on: Day{" "}
                                            {currentDay + 1}
                                        </Text>
                                    ) : (
                                        <></>
                                    )}
                                </Flex>
                            </>
                        ) : levels === "Controllers" ? (
                            <>
                            </>
                        ) : (
                            <ReactiveButton
                                disabled={entryTxIsLoaging || exitTxIsLoaging}
                                idleText={
                                    <Flex
                                        alignItems="center"
                                        justifyContent="flex-end"
                                    >
                                        {/* <Icon
                                            icon="clarity:logout-line"
                                            color="#f4516c"
                                            width="15"
                                            height="15"
                                        /> */}
                                        <Text fontSize="14px" ml="3px" bold>
                                            Add to Lobby
                                        </Text>
                                    </Flex>
                                }
                                size="large"
                                onClick={() => {
                                    lobbyEntryWrite(), closeModal();
                                }}
                            />
                        )}
                    </Flex>
                </Flex>
            </ReactModal>


            <ReactModal
                        isOpen={isLinkModalOpen}
                        onRequestClose={() => closeLinkModal()}
                        style={customStyles}
                    >
                        <Flex flexDirection="column" className="accountModal">
                            <Flex
                                alignItems="center"
                                mb="20px"
                                justifyContent="space-between"
                            >

                                        <Text
                                            fontSize="28px"
                                            ml="5px"
                                            mr="30px"
                                            color="white"
                                            bold
                                        >
                                            Your Referral Link
                                        </Text>
                                        <div id="copie"><Text fontSize="24px" ml="5px" mr="1px" color="yellow" >Copied</Text></div>
                                        <div
                                                onClick={() => {
                                                  navigator.clipboard.writeText(
                                                    "http://testnet2.tru2x.io/lobby?referralID=" +
                                                    Number(character.entryId)
                                                  );
                                                    copyToClipBoard()                                                 
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <Icon
                                                    icon="fluent:copy-24-filled"
                                                    color="rgba(199, 185, 178, 0.8)"
                                                    width="30"
                                                    height="30"
                                                />
                                            </div>

                            </Flex>


                            <Flex
                                alignItems="center"
                                justifyContent="space-between"
                            >
                                <Text fontSize="24px" ml="5px" mr="1px" color="cyan" >
                                      {"http://testnet2.tru2x.io/lobby?referralID=" +
                                      Number(character.entryId)}
                                </Text> 


                            </Flex>
                        </Flex>
                    </ReactModal>
                    

        </Flex>
    );
}
const Button = styled.button`
    border-radius: 8px;
    padding: 10px 18px;
    font-weight: 600;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: ${({ primary }) => (primary ? "#d9511c" : "#171615")};
    color: ${({ primary }) => (primary ? "#f1ede9" : "#d9511c")};
    border: ${({ secondary }) => secondary && "1px solid #d9511c"};
`;
export default Card;
